import React from 'react'
import { navigate } from 'gatsby'
import { css } from 'emotion'
import styled from 'styled-components'

import PrimaryButton from "components/PrimaryButton"
import AirbnbRedlines from "assets/images/airbnb-redlines-hero.jpg"
import HomepageBackgroundImage from "assets/images/homepage-hero-background.svg"
import theme from 'utils/theme'

const HeroIllustrationImageStyle = {
  margin: `0`,
  padding: `0`,
  boxShadow: `rgb(35, 41, 47, 0.08) 0px 8px 32px`,
}

const AirbnbRedlinesStyle = {
  backgroundColor: `transparent`,
  margin: `120px -25% 80px 80px`,
  paddign: `0`,
}

const PrimaryCopyContainer = {
  marginTop: `88px`,
}

const HeroSectionStyle = {
}

const WelcomeToStyle = {
  margin: `0 0 16px 0`,
  fontWeight: theme.fontWeight.semibold,
  fontFamily: `var(--title-font-family)`,
  textTransform: `uppercase`,
  color: theme.colors.black100,
  fontSize: theme.fontSize.xsmall,
  lineHeight: `1em`,
  letterSpacing: `2px`,
}

const TitleStyle = {
  color: theme.colors.black100,
  fontWeight: theme.fontWeight.heavy,
  fontSize: `56px`,
  lineHeight: `1em`,
  marginTop: `0px`,

  [`${theme.responsiveKeys.mobile}`]: {
    fontSize: `40px`,
  },
  [`${theme.responsiveKeys.ipad}`]: {
    fontSize: `48px`,
  },
}

const SubtitleStyle = {
  margin: `24px 0 0 0`,
  color: theme.colors.black90,
  fontWeight: theme.fontWeight.regular,
  lineHeight: `1.4em`,

  [`${theme.responsiveKeys.mobile}`]: {
    margin: `16px 0 0 0`,
  },
}

const HeaderRowStyle = {
  display: `flex`,
  flexDirection: `row`,
  height: `100%`,
}

const HeaderContainerStyle = {
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
  height: `100%`,

  [`${theme.responsiveKeys.mobile}`]: {
    padding: `0 0 40px 0`,
  },

}

const HeroSectionContainer = styled.div`
  background: url(${HomepageBackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom left;
`

const BrowserResourcesButtonStyle = {
  margin: `48px 0 0 0`,
}

class HomepageResourcesSection extends React.Component {

  constructor(props) {
    super(props);

    this.browseResourcesClicked = this.browseResourcesClicked.bind(this);
  };

  browseResourcesClicked(e) {
    e.preventDefault();

    navigate('/resources');
  };

  render() {
    return (
      <HeroSectionContainer>
        <div className="container">
          <div className="row">
            <div style={HeroSectionStyle}>
              <div className={css(HeaderContainerStyle)}>
                <div style={HeaderRowStyle}>
                  <div style={PrimaryCopyContainer} className="col-6 col-12-sm">
                    <p style={WelcomeToStyle}>Welcome to</p>
                    <h1 className={css(TitleStyle)}>Deconstructing <br />Design</h1>
                    <p className={css(SubtitleStyle)}>The best place to start learning design online.</p>
                    <div style={BrowserResourcesButtonStyle}>
                      <PrimaryButton title={"Browse Resources"} handleClick={this.browseResourcesClicked} />
                    </div>
                  </div>
                  <div style={AirbnbRedlinesStyle} className="hidden-sm">
                    <img src={`${AirbnbRedlines}`} alt={AirbnbRedlines} style={HeroIllustrationImageStyle} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeroSectionContainer>
    );
  }
};

export default HomepageResourcesSection;
