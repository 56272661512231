import React from 'react'
import { css } from 'emotion'

import theme from 'utils/theme'
import EmailInput from "components/EmailInput"
import SubscribeIllustrationDesktop from "assets/images/illustration-newsletter-desktop.svg"
import SubscribeIllustrationMobile from "assets/images/illustration-newsletter-mobile.svg"

const HomepageSubscribeSectionStyle = {
  backgroundColor: theme.colors.white,
}

const RowStyle = {
  display: `flex`,
  alignItems: `center`,
  padding: `80px 0`,

  [`${theme.responsiveKeys.mobile}`]: {
    display: `block`,
    padding: `0 0 64px 0`,
  },
}

const SubscribeContentStyle = {
  display: `flex`,
  flexDirection: `column`,
  alignItems: `flex-end`,

  [`${theme.responsiveKeys.mobile}`]: {
    alignItems: `center`,
  },
}

const SubscribeTitleStyle = {
  display: `flex`,
  letterSpacing: `-1px`,
  margin: `0 0 16px 0`,
  color: theme.colors.black90,
  textAlign: `right`,
}

const SubscribeStyle = {
  margin: `0 0 0 0`,
  textAlign: `right`,

  [`${theme.responsiveKeys.mobile}`]: {
    textAlign: `center`,
  },
}

const EmailInputWrapper = {
  display: `flex`,
  margin: `32px 0 0 0`,
  justifyContent: `center`,
  alignItems: `center`,
}

const SubscribeIllustrationContainerSmall = {
  display: `none`,
  padding: `0`,

  [`${theme.responsiveKeys.mobile}`]: {
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    padding: `0`,
  },
}

const SubscribeIllustrationContainerLarge = {
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,

  [`${theme.responsiveKeys.mobile}`]: {
    display: `none`,
  },
}

const SubscribeIllustrationStyle = {
  height: `100%`,
  width: `auto`,
}

const SubscribeIllustrationImageStyle = {
  height: `100%`,
  width: `auto`,
  margin: `0`,
  padding: `0`,
}

function HomepageSubscribeSection() {
  return (
    <div style={HomepageSubscribeSectionStyle}>
      <div className="container">
        <div className={`${css(RowStyle)} row`}>

            <div className={`${css(SubscribeIllustrationContainerSmall)} col-5`}>
              <div style={SubscribeIllustrationStyle}>
                <img style={SubscribeIllustrationImageStyle} src={SubscribeIllustrationMobile} alt={`Subscribe Illustration`} />
              </div>
            </div>

            <div className="col-7 col-12-sm">
              <div className={css(SubscribeContentStyle)}>
                <h2 style={SubscribeTitleStyle}>Join the newsletter</h2>
                <p className={css(SubscribeStyle)}>Every week the best resources and newest content is rounded up and delivered straight to your inbox.</p>
                <div style={EmailInputWrapper}>
                  <EmailInput type={'homepage'} />
                </div>
              </div>
            </div>

            <div className={`${css(SubscribeIllustrationContainerLarge)} col-5`}>
              <div style={SubscribeIllustrationStyle}>
                <img style={SubscribeIllustrationImageStyle} src={SubscribeIllustrationDesktop} alt={`Subscribe Illustration`} />
              </div>
            </div>

        </div>
      </div>
    </div>
  );
}

                  // <ConvertKitSubscribeForm />
// <EmailInput />

export default HomepageSubscribeSection;