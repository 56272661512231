import React from 'react'
import { navigate } from 'gatsby'
import { css } from 'emotion'

import PrimaryButton from 'components/PrimaryButton'
import LearnMoreIllustration from 'assets/images/illustration-whats-the-story.svg'
import theme from 'utils/theme'

const HomepageLearnMoreSectionStyle = {
  backgroundColor: theme.colors.white,
};

const WhatsTheStoryStyle = {
}

const WhatsTheStoryTitleStyle = {
  letterSpacing: `-1px`,
  margin: `0 0 24px 0`,
  color: theme.colors.black90,

  [`${theme.responsiveKeys.mobile}`]: {
    margin: `32px 0 24px 0`,
  },
}

const StoryExplainationStyle = {
  margin: `0 0 16px 0`,
}

const LearnMoreIllustrationStyle = {
  height: `auto`,
  margin: `0`,
}

const RowStyle = {
  display: `flex`,
  alignItems: `center`,
  padding: `80px 0`,

  [`${theme.responsiveKeys.mobile}`]: {
    display: `block`,
    padding: `64px 0`,
  },
}

const PrimaryButtonWrapperStyle = {
  margin: `40px 0 0 0`,
}

class HomepageLearnMoreSection extends React.Component {

  constructor(props) {
    super(props);

    // This binding is necessary to make `this` work in the callback
    this.primaryButtonAction = this.primaryButtonAction.bind(this);
  };

  primaryButtonAction(e) {
    e.preventDefault();

    navigate('/about');
  };

  render() {
    return (
      <div style={HomepageLearnMoreSectionStyle}>
        <div className="container">
          <div className={`${css(RowStyle)} row`}>

            <div className="col-5 col-12-sm">
              <img style={LearnMoreIllustrationStyle} src={LearnMoreIllustration} alt={`Learn More Illustration`} />
            </div>

            <div style={WhatsTheStoryStyle} className="col-7 col-12-sm">
              <h2 className={css(WhatsTheStoryTitleStyle)}>What's the story?</h2>
              <p style={StoryExplainationStyle}>Deconstructing Design is the resource I always wish I had.</p>
              <p style={StoryExplainationStyle}>The process to becoming a self-taught product designer wasn’t easy. As I was learning, I found myself spending as much time looking for helpful resources as I was learning the craft itself.</p>
              <p style={StoryExplainationStyle}>The goal for Deconstructing Design is to help curate, organize, and share the best design resources so people can spend less time researching, and more time designing.</p>
              <div style={PrimaryButtonWrapperStyle}>
                <PrimaryButton title={"Learn more"} handleClick={this.primaryButtonAction}></PrimaryButton>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  };
}

export default HomepageLearnMoreSection;
