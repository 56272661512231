import React from "react"
import { graphql } from 'gatsby'

import Layout from "layout/layout"
import SEO from "components/seo"
import HeroSection from "components/HomepageHeroSection"
import LearnMoreSection from "components/HomepageLearnMoreSection"
import ResourcesSection from "components/HomepageResourcesSection"
import SubscribeSection from "components/HomepageSubscribeSection"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HeroSection />
    <LearnMoreSection />
    <ResourcesSection />
    <SubscribeSection />
  </Layout>
);

export default IndexPage

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publicationDate], order: DESC }) {
      edges {
        node {
          title
          snippet
          slug
          publicationDate(formatString: "MMMM Do, YYYY")
          tags
          content {
            childMarkdownRemark {
              id
              html
              timeToRead
              wordCount {
                words
              }
            }
          }
        }
      }
    }
  }
`