import React from 'react'
import { Link } from 'gatsby'
import { css } from 'emotion'

import theme from 'utils/theme'

import IconHeroBooks from 'assets/images/icon-hero-books.svg'
import IconHeroBlogPosts from 'assets/images/icon-hero-blog-posts.svg'
import IconHeroOnlineResources from 'assets/images/icon-hero-online-resources.svg'

import ResourceType from 'enums/ResourceType'

const ResourceInfo = {
  [ResourceType.BOOK]: {
    image: IconHeroBooks,
    title: 'Books',
    subtitle: 'I’m working towards curating an exhaustive list of the most helpful books myself and others have used to improve their design chops.',
    cta: 'View books',
    linkPath: '/resources#books',
  },
  [ResourceType.BLOG_POST]: {
    image: IconHeroBlogPosts,
    title: 'Blog posts',
    subtitle: 'Posts range from breakdowns of popular sites to simple posts on foundational topics like hierarchy, contrast, and typography.',
    cta: 'View blog posts',
    linkPath: '/blog',
  },
  [ResourceType.ONLINE_RESOURCE]: {
    image: IconHeroOnlineResources,
    title: 'Online resources',
    subtitle: 'Ever find yourself wondering what content is helpful and what content is “worth it?” I can guarantee you – all of these are worth your time.',
    cta: 'View online resources',
    linkPath: '/resources#online-resources',
  },
  [ResourceType.PERSON]: {
    image: IconHeroOnlineResources,
    title: 'Poeple',
    subtitle: '',
    cta: 'View people',
    linkPath: '/resources#people',
  },
}

const HeroResourceDetailBoxStyle = {
  display: `flex`,
  flexDirection: `column`,
  width: `100%`,
  backgroundColor: theme.colors.white,
  padding: `0`,
  fontFamily: `var(--font-family)`,

  [`${theme.responsiveKeys.mobile}`]: {
    padding: `0px 0px 32px 0px`,
  },
};

const ImageContainerStyle = {
  margin: `48px 0 0 0`,

  [`${theme.responsiveKeys.mobile}`]: {
    margin: `32px 0 0 0`,
  },
}

const IconStyle = {
  margin: `0`,
  padding: `0`,
}

const TitleStyle = {
  margin: `24px 0 0 0`,

  [`${theme.responsiveKeys.mobile}`]: {
    margin: `16px 0 0 0`,
  },
}

const DescriptionStyle = {
  margin: `12px 0 0 0`,
}

const LinkStyle = {
  fontFamily: `var(--font-family)`,
  margin: `32px 0 0 0`,
  weight: theme.fontWeight.medium,
  color: theme.colors.accent,
  borderBottom: `2px solid ${theme.colors.accent}`,
  paddingBottom: `3px`,
  width: `fit-content`,
}

class HeroResourceDetailBox extends React.Component {

  render() {
    const image = ResourceInfo[this.props.resource].image;
    const title = ResourceInfo[this.props.resource].title;
    const subtitle = ResourceInfo[this.props.resource].subtitle;
    const cta = ResourceInfo[this.props.resource].cta;
    const linkPath = ResourceInfo[this.props.resource].linkPath;

    return (
      <div className={css(HeroResourceDetailBoxStyle)}>
        <div className={css(ImageContainerStyle)}>
          <img style={IconStyle} src={image} alt={image} />
        </div>
        <h3 className={css(TitleStyle)}>{title}</h3>
        <p style={DescriptionStyle}>{subtitle}</p>
        <Link style={LinkStyle} to={linkPath}>{cta}</Link>
      </div>
    );
  }
}

export default HeroResourceDetailBox