import React from 'react'
import { css } from 'emotion'

import theme from 'utils/theme'
import HeroResourceDetailBox from 'components/HeroResourceDetailBox'
import ResourceType from 'enums/ResourceType'

const HomepageResourcesSectionStyle = {
  backgroundColor: theme.colors.white,
};

const RowStyle = {
  display: `flex`,
  flexDirection: `column`,
  padding: `80px 0`,

  [`${theme.responsiveKeys.mobile}`]: {
    display: `block`,
    padding: `64px 0`,
  },
}

const InnerRowStyle = {
  display: `flex`,
  justifyContent: `space-between`,
  padding: `0`,
  margin: `24px 0 0 0`,

  [`${theme.responsiveKeys.mobile}`]: {
    margin: `0`,
    display: `block`,
  },
}

const ResourcesTitleStyle = {
  display: `block`,
  letterSpacing: `-1px`,
  color: theme.colors.black90
}

class HomepageResourcesSection extends React.Component {

  constructor(props) {
    super(props);

    this.resources = [ResourceType.BOOK, ResourceType.BLOG_POST, ResourceType.ONLINE_RESOURCE]
  };

  render() {
    return (
      <div style={HomepageResourcesSectionStyle}>
        <div className="container">
          <div className="row" className={css(RowStyle)}>
            <h2 style={ResourcesTitleStyle} className="col-12">What kind of resources?</h2>

            <div className={css(InnerRowStyle)}>
              {
                this.resources.map(resource => (
                  <div className="col-4 col-12-sm" key={`${resource}`}>
                    <HeroResourceDetailBox resource={resource} />
                  </div>
                ))
              }
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default HomepageResourcesSection;
